import React, { useState } from 'react'
import { Text } from 'pcln-design-system'
import LinksWidgetContainer from './styled'
import ExpandableLinksBlock from '../LinksBlock'
import { LinksApiResponse } from '../types'

type WidgetProps = {
  data: LinksApiResponse
  isMobile?: boolean
}

function LinksWidget({ data, isMobile }: WidgetProps) {
  const { linkSections } = data.data
  const [seeMore, setSeeMore] = useState(false)
  const totalSections = linkSections?.length || 0
  const partSize = Math.floor(totalSections / 3)
  const remainder = totalSections % 3

  const part1 = linkSections?.slice(0, partSize + (remainder > 0 ? 1 : 0))
  const part2 = linkSections?.slice(
    part1.length,
    part1.length + partSize + (remainder > 1 ? 1 : 0)
  )
  const part3 = linkSections?.slice(part1.length + part2.length)

  const formattedSections =
    isMobile && !seeMore ? [part1] : [part1, part2, part3]
  return (
    <>
      {!!linkSections && (
        <LinksWidgetContainer>
          {formattedSections.map((linkSectionsPart, sectionIndex) => {
            return (
              <div
                key={`fd-column-${
                  linkSectionsPart[0]?.mainLink.text || sectionIndex
                }`}
              >
                {linkSectionsPart.map(linkSection => {
                  return (
                    <ExpandableLinksBlock
                      linksData={linkSection}
                      key={linkSection.mainLink.text}
                    />
                  )
                })}
              </div>
            )
          })}
          {isMobile ? (
            <Text
              px={2}
              pb={2}
              bold
              textAlign="right"
              onClick={() => setSeeMore(!seeMore)}
            >
              {isMobile && seeMore ? 'See Less' : 'See More'}
            </Text>
          ) : null}
        </LinksWidgetContainer>
      )}
    </>
  )
}

export default LinksWidget
