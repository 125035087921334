import React from 'react'
import { Dialog, Box, Image } from 'pcln-design-system'
import { expressDeals } from '@/constants/content'
import StyledHeading from '@/components/StyledHeading'
import { handleSubmitForExpressDeals } from '../SearchForm/utils'
import Form from '../SearchForm/Form'
import { PromoDeal } from '../constants'

const expressDealLogo =
  'https://s1.pclncdn.com/design-assets/brand-assets/express-deals-logo-registard.svg'
export default function ExpressDealDialog({
  isOpen,
  closeDialog,
  isMobile
}: {
  isMobile?: boolean
  isOpen: boolean
  closeDialog: () => void
  gaCategory: 'PCLN_HOME' | 'HOTEL_HOME'
}) {
  const mobileProps = {
    sheet: true,
    fullWidth: true,
    headerShowCloseButton: true,
    headerContent: '  ',
    showCloseButton: false
  } as const
  const dialogProps = isMobile
    ? mobileProps
    : ({ overflowY: 'visible', overflowX: 'visible' } as const)
  return (
    <Dialog
      open={isOpen}
      ariaDescription="Search for Express Deals"
      ariaTitle="Hotel Express Deal Dialog"
      borderRadius="2xl"
      innerContentOverflow="visible"
      onOpenChange={closeDialog}
      scrimColor="dark"
      scrimDismiss
      size={isMobile ? 'full' : 'md'}
      {...dialogProps}
    >
      <Box pt={[null, null, null, 3]} pb={3} px={3}>
        <Image src={expressDealLogo} height="24px" width="164px" />
        <StyledHeading
          text={expressDeals.hotelsHeadline()}
          fontSize={[2, null, null, 3]}
          lineHeight="display"
          tag="h2"
        />
        <Box py={2}>
          <Form
            onSubmit={values => handleSubmitForExpressDeals({ ...values })}
            promoDeal={PromoDeal.EXPRESS_DEAL}
          />
        </Box>
      </Box>
    </Dialog>
  )
}
