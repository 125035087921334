import styled from 'styled-components'

export default styled.div`
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: 40px;
  display: grid;
  grid-auto-flow: row;
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`
