import React from 'react'
import StyledHeading from '@/components/StyledHeading'
import useCityNameInFormHeadlineForSEM from '@/hooks/useCityNameInFormHeadlineForSEM'
import useContentfulSearchFormHeadlines from '@/hooks/useContentfulSearchFormHeadlines'
import { LOCATION_SEARCH_TYPE } from '@/types'
import useSEMSearchHeadline from '@/hooks/useSEMSearchHeadline'
import { PRIMARY_HEADING_FALLBACK } from './constants'

type FormHeadlineType = {
  location: LOCATION_SEARCH_TYPE | undefined
}

function FormHeadline({ location }: FormHeadlineType) {
  const searchForm = useContentfulSearchFormHeadlines('searchForm')
  const prePopHeadline = useCityNameInFormHeadlineForSEM(location)
  const SEMHeadline = useSEMSearchHeadline()
  const headline =
    prePopHeadline ??
    SEMHeadline ??
    (searchForm?.headline || PRIMARY_HEADING_FALLBACK)

  return (
    <StyledHeading
      tag="h1"
      color="text.heading"
      textStyle={['heading3', null, null, null, 'heading2']}
      text={headline}
    />
  )
}

export default FormHeadline
