import React from 'react'
import { type MarginProps } from 'styled-system'
import { Flex, Text, getPaletteColor } from 'pcln-design-system'
import styled from 'styled-components'
import { Graph } from 'pcln-icons'
import { LOCATION_SEARCH_TYPE } from '@/types'
import useBootstrapData from '@/hooks/useBootstrapData'

const popularCityIds: number[] = [
  3000015284, 3000003349, 3000003496, 3000020633, 3000002241, 3000005381,
  3000002573, 3000003311, 3000014521, 3000019836, 3000002557, 3000021763,
  3000016152, 3000020856, 3000003899, 3000003192, 3000012874, 3000021212,
  3000021312, 3000002228, 3000008602, 3000003249, 3000001947, 3000008434,
  3000022148, 3000003431, 3000006556, 3000016809, 3000021082, 3000016799,
  3000016162, 3000001349, 3000019204, 3000015300, 3000019766, 3000023880,
  3000023414, 3000003032, 3000003369, 3000010974, 3000019211, 3000002250,
  3000014999, 3000002244, 3000020830, 3000020605, 3000020669, 3000016338,
  3000020556, 3000015474
]

const Wrapper = styled(Flex).attrs(() => ({
  py: 1,
  px: 2
}))`
  border: 1px solid ${getPaletteColor('border.base')};
`

function TopBooked(props: MarginProps) {
  return (
    <Wrapper color="background.light" borderRadius="md" {...props}>
      <Graph mr={1} size={16} color="promoPrimary" />
      <Text bold color="promoPrimary" fontSize={0} textAlign="center" m="auto">
        TOP BOOKED DESTINATION
      </Text>
    </Wrapper>
  )
}

type UrgencyMessageProps = MarginProps & {
  location?: LOCATION_SEARCH_TYPE
}

export default function UrgencyMessage({
  location,
  ...props
}: UrgencyMessageProps) {
  const { prePopData } = useBootstrapData()
  const endLocation = prePopData?.hotels?.endLocation
  const hasCity = !!endLocation?.cityName && endLocation?.type === 'CITY'
  const cityId = Number(location?.cityID) || Number(endLocation?.cityID)
  const isPopularCity = hasCity && popularCityIds.includes(cityId)
  return isPopularCity ? <TopBooked {...props} /> : null
}
