import React, { ReactElement, ReactNode, useContext, useEffect } from 'react'
import pclnExperimentation from '@pcln/experimentation'
import ExperimentContext from '@/context/ExperimentContext'
import childByTagOr from './experiment-helpers'

function Experiment({ children }: { children?: React.ReactNode }) {
  return <>{children}</>
}

function Original({ children }: { children?: React.ReactNode }) {
  return <>{children}</>
}

function ConditionalRender({
  experimentCode,
  variantValue,
  children,
  experiment,
  original,
  triggerExperiment = () => true
}: {
  experimentCode: string
  variantValue?: string
  children?: ReactNode
  experiment?: ReactElement | (() => ReactElement)
  original?: ReactElement | (() => ReactElement)
  triggerExperiment?: () => boolean
}) {
  const { [experimentCode]: setiTest = 'DEFAULT' } =
    useContext(ExperimentContext)

  const shouldTriggerExperiment = triggerExperiment()

  useEffect(() => {
    if (shouldTriggerExperiment) {
      pclnExperimentation.fireImpression(experimentCode)
    }
  }, [experimentCode, setiTest, shouldTriggerExperiment])

  const callVariant =
    (setiTest === variantValue ||
      (setiTest !== 'DEFAULT' && setiTest !== 'CONTROL')) &&
    shouldTriggerExperiment

  return callVariant
    ? childByTagOr(Experiment, experiment, children)
    : childByTagOr(Original, original, children)
}

ConditionalRender.Experiment = Experiment
ConditionalRender.Original = Original

export { ConditionalRender as default, Experiment, Original }
